import LoaderUI from "components/loader";
import { useFormik } from "formik";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { TiArrowBack } from "react-icons/ti";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { createUniversity } from "../university.service";
import { UniversityCreateSchema } from "./university.schema";
import { dummyAvatar } from "assets";
import { FcInfo } from "react-icons/fc";

const CreateNewUniversity = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // Use non-optional type for input reference
  const imageInputRef = useRef<HTMLInputElement>(null);
  const matriculationInputRef = useRef<HTMLInputElement>(null);

  // State for managing selected image file
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedMatriculation, setSelectedImageMatriculation] = useState<File | null>(null);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialValues: any = {
    university_logo: null,
    university_name: "",
    university_email: "",
    university_phoneNumber: "",
    university_password: "",
    university_matriculation: null
  };
  const clearSelectedImageAndInput = () => {
    if (imageInputRef?.current) {
      imageInputRef.current.value = "";
    }
    setSelectedImage(null);
    setFieldValue("university_logo", "");
  };
  const clearMatriculation = () => {
    if (matriculationInputRef?.current) {
      matriculationInputRef.current.value = "";
    }
    setSelectedImageMatriculation(null);
    setFieldValue("university_matriculation", "");
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      let createUniversityFormData: any = new FormData();
      createUniversityFormData.append("name", values.university_name);
      createUniversityFormData.append("email", values.university_email);
      createUniversityFormData.append(
        "phoneNumber",
        `+${values?.university_phoneNumber}`
      );
      createUniversityFormData.append("password", values.university_password);
      createUniversityFormData.append("uniMarticulation", values.university_matriculation);
      createUniversityFormData.append("logo", values.university_logo);
      createUniversityFormData.append("role", "university");

      const response = await createUniversity(createUniversityFormData);
      navigate("/admin/university-management");
      toast.success(
        response?.message ||
        "University Added Successfully"
      );
      resetForm();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
        "Oops, something went wrong. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema: UniversityCreateSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  const handleImageChange = (event: any) => {
    // Extract the selected file from the event
    const file = event?.target?.files[0];
    // Reset the selected image to null
    setSelectedImage(null);

    // Check if a file has been selected
    if (file !== undefined) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

      // Check if the selected file type is allowed
      if (file && allowedTypes?.includes(file?.type)) {
        // Set the file value in the form field and update selected image
        setFieldValue("university_logo", file);
        setSelectedImage(file);
      } else {
        // Reset selected image to null and input value, display an alert for invalid file type
        clearSelectedImageAndInput();

        toast("Invalid file type. Please select PNG, JPG, JPEG file.", {
          icon: <FcInfo className="h-6 w-6" />,
        });
      }
    }
  };
  const handleMatriculationUpload = (event: any) => {
    // Extract the selected file from the event
    const file = event?.target?.files[0];
    // Reset the selected image to null
    setSelectedImageMatriculation(null);

    // Check if a file has been selected
    if (file !== undefined) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

      // Check if the selected file type is allowed
      if (file && allowedTypes?.includes(file?.type)) {
        // Set the file value in the form field and update selected image
        setFieldValue("university_matriculation", file);
        setSelectedImageMatriculation(file);
      } else {
        // Reset selected image to null and input value, display an alert for invalid file type
        clearMatriculation();
        toast("Invalid file type. Please select PNG, JPG, JPEG file.", {
          icon: <FcInfo className="h-6 w-6" />,
        });
      }
    }
  };


  if (loading) {
    return <LoaderUI />;
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="w-full flex-auto rounded-lg bg-white p-6 shadow-lg">
          <div className="flex items-center justify-between rounded-lg border-b-2 border-gray-200 bg-gray-200 p-4 px-6 py-3">
            <p className="text-lg font-semibold text-indigo-900">
              Create a University by filling the following details
            </p>
          </div>
          <div className="my-5 flex flex-col items-center sm:flex-row sm:space-y-0">
            {/* University Logo */}
            <div className="mx-auto my-4">
              <input
                type="file"
                name="university_logo"
                id="university_logo"
                ref={imageInputRef}
                className="hidden"
                accept="image/*"
                onChange={(e) => handleImageChange(e)}
                onBlur={handleBlur}
                onKeyDown={(e) => {
                  if (e.key === "Escape") {
                    handleBlur(e);
                  }
                }}
              />
              <img
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : dummyAvatar
                }
                alt="university logo"
                className="h-32 w-32 cursor-pointer rounded-full object-cover p-1 ring-2 ring-red-400"
                onClick={() => imageInputRef?.current?.click()}
              />
              {errors?.university_logo && touched?.university_logo && (
                <div className="text-sm text-red-500">
                  {errors?.university_logo as string}
                </div>
              )}
            </div>
          </div>



          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div className="mb-4">
              <label
                htmlFor="university_name"
                className="mb-2 block text-sm font-medium text-indigo-900"
              >
                University Name:
              </label>
              <input
                type="text"
                id="university_name"
                name="university_name"
                placeholder="Enter University Name"
                className="block w-full rounded-lg border border-gray-300 bg-gray-100 px-4 py-2 text-gray-900 shadow-sm focus:border-blue-500 focus:outline-none"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.university_name}
              />
              {errors?.university_name && touched?.university_name && (
                <div className="text-sm text-red-500 mt-1">
                  {errors?.university_name as string}
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="university_email"
                className="mb-2 block text-sm font-medium text-indigo-900"
              >
                University Email:
              </label>
              <input
                type="email"
                id="university_email"
                name="university_email"
                placeholder="Enter Email Address"
                className="block w-full rounded-lg border border-gray-300 bg-gray-100 px-4 py-2 text-gray-900 shadow-sm focus:border-blue-500 focus:outline-none"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.university_email}
              />
              {errors?.university_email && touched?.university_email && (
                <div className="text-sm text-red-500 mt-1">
                  {errors?.university_email as string}
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="university_password"
                className="mb-2 block text-sm font-medium text-indigo-900"
              >
                Password:
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="university_password"
                  name="university_password"
                  placeholder="******"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-100 px-4 py-2 text-gray-900 shadow-sm focus:border-blue-500 focus:outline-none"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.university_password}
                />
                <span
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                </span>
              </div>
              {errors?.university_password && touched?.university_password && (
                <div className="text-sm text-red-500 mt-1">
                  {errors?.university_password as string}
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="university_phoneNumber"
                className="mb-2 block text-sm font-medium text-indigo-900"
              >
                University Phone:
              </label>
              <div className="w-full">
                <PhoneInput
                  country="de"
                  disableDropdown={false}
                  countryCodeEditable={false}
                  enableSearch
                  searchClass="rounded-lg border border-gray-300 bg-gray-100 focus:border-blue-500 focus:outline-none"
                  placeholder="Enter Phone Number"
                  value={values?.university_phoneNumber}
                  onChange={(phoneNumber: string) => {
                    setFieldValue("university_phoneNumber", phoneNumber);
                  }}
                  onBlur={() => setFieldTouched("university_phoneNumber")}
                />
              </div>
              {touched?.university_phoneNumber && errors?.university_phoneNumber && (
                <div className="text-sm text-red-500 mt-1">
                  {errors?.university_phoneNumber as string}
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="university_matriculation"
                className="mb-2 block text-sm font-medium text-indigo-900"
              >
                University Matriculation Letter:
              </label>
              <div className="relative">
                <input
                  type="file"
                  name="university_matriculation"
                  id="university_matriculation"
                  ref={matriculationInputRef}
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => handleMatriculationUpload(e)}
                  onBlur={handleBlur}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      handleBlur(e);
                    }
                  }}
                />
                <div
                  className="cursor-pointer border border-gray-300 bg-gray-100 p-2 rounded-lg flex items-center justify-center overflow-hidden"
                  onClick={() => matriculationInputRef?.current?.click()}
                >
                  {selectedMatriculation ? (
                    <img
                      src={URL.createObjectURL(selectedMatriculation)}
                      alt="University Matriculation Letter"
                      className="max-h-60 max-w-full object-contain"
                    />
                  ) : (
                    <div className="text-gray-500">Upload Matriculation Letter</div>
                  )}
                </div>
                {errors?.university_matriculation && touched?.university_matriculation && (
                  <div className="text-sm text-red-500 mt-1">
                    {errors?.university_matriculation as string}
                  </div>
                )}
              </div>
            </div>
          </div>


          <hr className="my-6 border-t border-gray-300" />
          <div className="flex justify-end gap-2">
            <button
              type="button"
              className="border-transparent inline-flex items-center justify-center rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              onClick={() => navigate("/admin/university-management")}
            >
              <span>Back</span>
              <TiArrowBack className="h-4 w-4" />
            </button>
            <button
              type="submit"
              className="border-transparent inline-flex items-center justify-center rounded-md border bg-green-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Create University
            </button>
          </div>
        </div >
      </form >
    </>
  );
};

export default CreateNewUniversity;
