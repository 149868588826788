import { labels } from "utils/constants";

export function getLabel(enumValue: string) {
  return labels[enumValue] || "-";
}

export const clearAndLogout = (): void => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

export function getToken(): string {
  let value: string = "";
  if (typeof window !== "undefined") {
    value = localStorage.getItem("accessToken") || "";
  }
  return value;
}

export const removeFirstMatch = (
  originalString: string,
  substringToRemove: string
) => {
  const regex = new RegExp(substringToRemove?.split("+")?.[1]);
  return originalString.replace(regex, "");
};

export const getEnumLabel = (inputString: string) => {
  const string = inputString.replace(/ /g, "_").toUpperCase();
  return string;
};
