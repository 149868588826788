import axios from "axios";
import { ISignInFormTypes } from "./signin.types";

const baseUrl = process.env.REACT_APP_API_URL;

export const adminLogin = async (values: ISignInFormTypes) => {
  try {
    const response = await axios.post(`${baseUrl}/auth/login`, values);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
