import LoaderUI from "components/loader";
import { useFormik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { TiArrowBack } from "react-icons/ti";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { createLawyer } from "../lawyer.service";
import { LawyerCreateSchema } from "./lawyer.schema";
import { ILawyer } from "../lawyer.types";

const CreateNewLawyer = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    lawyer_name: "",
    lawyer_email: "",
    lawyer_phoneNumber: "",
    lawyer_password: "",
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      const createLawyerPayload: ILawyer = {
        name: values?.lawyer_name,
        email: values?.lawyer_email,
        phoneNumber: `+${values?.lawyer_phoneNumber}`,
        password: values?.lawyer_password,
        role: "lawyer",
      };
      const response = await createLawyer(createLawyerPayload);
      toast.success(response?.message || "Lawyer Added Successfully");
      resetForm();
      navigate("/admin/lawyer-management");
    } catch (error) {
      toast.error("Oops, something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema: LawyerCreateSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  if (loading) {
    return <LoaderUI />;
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="w-full flex-auto rounded-lg bg-white p-6 shadow-lg">
          <div className="mb-9 flex items-center justify-between rounded-lg border-b-2 border-gray-200 bg-gray-200 p-4 px-6 py-3">
            <p className="text-lg font-semibold text-indigo-900">
              Create a Lawyer by filling the following details
            </p>
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div className="mb-4">
              <label
                htmlFor="lawyer_name"
                className="mb-2 block text-sm font-medium text-indigo-900"
              >
                Lawyer Name:
              </label>
              <input
                type="text"
                id="lawyer_name"
                name="lawyer_name"
                placeholder="Enter Lawyer Name"
                className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.lawyer_name}
              />
              {errors?.lawyer_name && touched?.lawyer_name && (
                <>
                  <div className="text-sm text-red-500">
                    {errors?.lawyer_name as string}
                  </div>
                </>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="lawyer_email"
                className="mb-2 block text-sm font-medium text-indigo-900"
              >
                Lawyer Email:
              </label>
              <input
                type="email"
                id="lawyer_email"
                name="lawyer_email"
                placeholder="Enter Email Address"
                className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.lawyer_email}
              />
              {errors?.lawyer_email && touched?.lawyer_email && (
                <>
                  <div className="text-sm text-red-500">
                    {errors?.lawyer_email as string}
                  </div>
                </>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="lawyer_name"
                className="mb-2 block text-sm font-medium text-indigo-900"
              >
                Password:
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="lawyer_password"
                  name="lawyer_password"
                  placeholder="******"
                  className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.lawyer_password}
                />
                <span
                  className="absolute right-5 top-1/2 -translate-y-1/2 transform cursor-pointer text-gray-500"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                </span>
              </div>
              {errors?.lawyer_password && touched?.lawyer_password && (
                <>
                  <div className="text-sm text-red-500">
                    {errors?.lawyer_password as string}
                  </div>
                </>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="lawyer_phoneNumber"
                className="mb-2 block text-sm font-medium text-indigo-900"
              >
                Lawyer Phone:
              </label>
              <div className="w-full">
                <PhoneInput
                  country="de"
                  disableDropdown={false}
                  countryCodeEditable={false}
                  enableSearch
                  searchClass="rounded-lg border-gray-300 bg-gray-100 focus:border-blue-500 focus:outline-none"
                  placeholder="Enter Phone Number"
                  value={values?.lawyer_phoneNumber}
                  onChange={(phoneNumber: string) => {
                    setFieldValue("lawyer_phoneNumber", phoneNumber);
                  }}
                  onBlur={() => setFieldTouched("lawyer_phoneNumber")}
                />
              </div>
              {touched?.lawyer_phoneNumber && errors?.lawyer_phoneNumber && (
                <div className="text-sm text-red-500">
                  {errors?.lawyer_phoneNumber as string}
                </div>
              )}
            </div>
          </div>
          <hr className="my-6 border-t border-gray-300" />
          <div className="flex justify-end gap-2">
            <button
              type="button"
              className="border-transparent inline-flex items-center justify-center rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              onClick={() => navigate("/admin/lawyer-management")}
            >
              <span>Back</span>
              <TiArrowBack className="h-4 w-4" />
            </button>
            <button
              type="submit"
              className="border-transparent inline-flex items-center justify-center rounded-md border bg-green-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Create Lawyer
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateNewLawyer;
