export const SUBCATEGORIES: Record<string, string[]> = {
  Study: [
    "Application",
    "After Studies",
    "Communication",
    "Documents",
    "Resident Permit",
    "Tax Id and Social Security Number",
    "Work",
    "Travel",
    "Duties",
  ],
  "Post-study": ["Application", "Documents", "Work", "Travel", "Duties"],
};
export const CATEGORIES: string[] = ["Study", "Post-study"];
