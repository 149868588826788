import { emailRegex } from "utils/constants";
import { removeFirstMatch } from "utils/services/helpers";
import * as Yup from "yup";

const FILE_SIZE = 10 * 1024 * 1024; // 10 MB
const SUPPORTED_FORMATS = ["image/jpeg", "image/png", "image/gif"];

export const UniversityCreateSchema = Yup.object().shape({
  university_matriculation: Yup.mixed<File>()
    .required("University Matriculation letter is required")
    .test("fileFormat", "Unsupported Format", (value) => {
      if (value) {
        return (value as File).type.startsWith("image/");
      }
      return false;
    }),
  university_logo: Yup.mixed()
    .required("Profile Image is Required")
    .test(
      "fileType",
      "Invalid file type, only JPG, JPEG, PNG are allowed",
      (value) => {
        const typedValue = value as File | undefined;

        if (typedValue) {
          return SUPPORTED_FORMATS.includes(typedValue?.type as string);
        }
        return false;
      }
    )
    .test(
      "fileSize",
      "File size is too large, maximum allowed size is 5 MB",
      (value) => {
        const typedValue = value as File | undefined;
        return typedValue && typedValue.size <= FILE_SIZE;
      }
    ),
  // university_matriculation: Yup.mixed()
  //   .required("University Matriculation is Required")
  //   .test(
  //     "fileType",
  //     "Invalid file type, only JPG, JPEG, PNG are allowed",
  //     (value) => {
  //       const typedValue = value as File | undefined;

  //       if (typedValue) {
  //         return SUPPORTED_FORMATS.includes(typedValue?.type as string);
  //       }
  //       return false;
  //     }
  //   )
  //   .test(
  //     "fileSize",
  //     "File size is too large, maximum allowed size is 5 MB",
  //     (value) => {
  //       const typedValue = value as File | undefined;
  //       return typedValue && typedValue.size <= FILE_SIZE;
  //     }
  //   ),
  university_name: Yup.string()
    .min(2, "University name must be at least 2 characters long")
    .max(50, "University name cannot exceed 50 characters.")
    .required("University name is required"),
  university_email: Yup.string()
    .matches(emailRegex, "Invalid email address")
    .required("Email is Required"),
  university_phoneNumber: Yup.string()
    .required("Mobile number is required")
    .min(7, "Minimum 7 digits required")
    .test("checkEmptyValue", "Mobile number is required", function (value) {
      const { countryCode } = this.parent;
      return `+${value}` !== countryCode;
    })
    .test("checkMinNumber", "Enter Valid Mobile Number", function (value) {
      const { countryCode } = this.parent;
      return removeFirstMatch(value, countryCode?.split("+")?.[1])?.length >= 4;
    })
    .matches(/^\d{1,14}$/, "Invalid phone number"),
  university_password: Yup.string()
    .trim()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,14}$/,
      "Password should be 8-14 characters with at least one lowercase, one uppercase, one number, and one special character from @$!%*?&#."
    ),
});
