export const emailRegex =
  /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+(?:com|co\.in)$/;
export const userNameRegex = /^[a-zA-Z0-9_.-]*$/;
export const nameRegex = /^[a-zA-Z0-9_\\.\-\s]*$/;
export const websiteRegex =
  /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;

interface ILabels {
  [key: string]: string;
}

export const labels: ILabels = {
  OPEN: "Open",
  READY_TO_CHECK: "Ready to Check",
  WAITING_FOR_CORRECTION: "Waiting for Correction",
  CORRECTION_READY: "Correction Ready",
  READY_FOR_LEA: "Ready for LEA",
  AT_LEA: "At LEA",
  INVITED: "Invited",
  READY_FOR_INVOICE: "Ready for Invoice",
  INVOICED: "Invoiced",
};
