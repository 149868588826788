import { ViewIcon, dummyAvatar } from "assets";
import LoaderUI from "components/loader";
import WidgetCard from "components/widgetcard";
import { useFormik } from "formik";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaCheckCircle, FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";
import PhoneInput from "react-phone-input-2";
import { useNavigate, useParams } from "react-router-dom";
import { IStudent } from "views/admin/StudentManagement/student.types";
import { fetchLawyerById, updateLawyerById } from "../lawyer.service";
import { LawyerUpdateValidationSchema } from "./lawyer.schema";
import ReactPaginate from "react-paginate";
import ModalView from "components/modal";
import { updateStudentById } from "views/admin/StudentManagement/student.service";
import { getLabel } from "utils/services/helpers";

const LawyerDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [lawyerDetails, setLawyerDetails] = useState(null);
  const [studentListOfLawyer, setStudentListOfLawyer] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [totalStudentCount, setTotalStudents] = useState(0);
  const [student, setStudent] = useState(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [isShowAddressModal, setIsShowAddressModal] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const navigate = useNavigate();

  const handleToggleActivation = async (
    studentId: string,
    isDeleted: boolean
  ) => {
    setLoading(true);
    try {
      const response = await updateStudentById(studentId, {
        isDeleted: !isDeleted,
      });
      await fetchLawyerDetails();
      toast.success(
        response?.message ||
          `${student?.name || "Student"} ${
            isDeleted ? "is now active" : "has been deactivated"
          } successfully!`
      );
    } catch (error) {
      toast.error("Oops, something went wrong. Please try again later.");
    } finally {
      setLoading(false);
      setIsDeleteModal(false);
    }
  };

  // Function to open the address modal
  const showDeleteModal = (student: IStudent) => {
    setIsDeleteModal(true);
    setStudent(student);
  };

  // Function to close the address modal
  const hideDeleteModal = () => {
    setIsDeleteModal(false);
    setStudent(null);
  };

  // Function to open the address modal
  const showAddressModal = (student: IStudent) => {
    setIsShowAddressModal(true);
    setStudent(student);
  };

  // Function to close the address modal
  const hideAddressModal = () => {
    setIsShowAddressModal(false);
    setStudent(null);
  };

  // Function to handle page change
  const handleChangePage = ({ selected }: { selected: number }) => {
    setCurrentPage(selected); // Increment selected by 1 because API page starts from 1
  };

  // Function to handle rows per page change
  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(Number(e?.target?.value)); // Set rows per page based on selected value
    setCurrentPage(0);
  };

  const handleViewStudentDetails = (studentId: string) => {
    if (studentId) {
      let url = `/admin/student-management/${studentId}`;
      navigate(url);
    }
  };

  // Enable Edit Mode
  const toggleEditMode = () => {
    setIsEditing(true);
  };

  // Disable Edit Mode
  const handleCancelEdit = () => {
    setIsEditing(false);
    resetForm();
  };

  // Function to handle sorting when a column header is clicked
  const handleSortColumn = (columnName: string) => {
    if (sortBy === columnName) {
      // If the clicked column is already the sorting column
      // Toggle the sorting direction from ascending to descending or vice versa
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If a new column is clicked for sorting
      setSortBy(columnName); // Set the sorting column to the clicked column
      setSortDirection("asc"); // Reset sorting direction to ascending
    }
  };

  // Inside the component
  const sortedStudentList =
    Array.isArray(studentListOfLawyer) &&
    [...studentListOfLawyer]?.sort((a, b) => {
      if (sortBy) {
        if (sortBy === "visaExpiryDate") {
          // Sorting by visa expiry date
          const dateA = new Date(a[sortBy] || 0); // Convert to Date, default to 0 if invalid date
          const dateB = new Date(b[sortBy] || 0); // Convert to Date, default to 0 if invalid date
          if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
            // Ensure both dates are valid
            return sortDirection === "asc"
              ? dateA.getTime() - dateB.getTime()
              : dateB.getTime() - dateA.getTime();
          } else {
            return 0; // Return 0 if any date is invalid
          }
        } else {
          // Sorting by other columns
          if (a[sortBy] < b[sortBy]) {
            return sortDirection === "asc" ? -1 : 1;
          }
          if (a[sortBy] > b[sortBy]) {
            return sortDirection === "asc" ? 1 : -1;
          }
          return 0;
        }
      } else {
        return 0;
      }
    });

  // Fetch Lawyer's Details
  const fetchLawyerDetails = useCallback(async () => {
    setLoading(true);
    const response = await fetchLawyerById(id, currentPage + 1, rowsPerPage);
    if (response?.status === 200) {
      setLawyerDetails(response?.data?.lawyerDetails);
      setStudentListOfLawyer(response?.data?.studentDetails?.results);
      setTotalPageCount(response?.data?.studentDetails?.totalPages);
      setTotalStudents(response?.data?.studentDetails?.totalCount);
    }
    setLoading(false);
  }, [id, currentPage, rowsPerPage]);

  useEffect(() => {
    fetchLawyerDetails();
  }, [id, fetchLawyerDetails]);

  const initialValues = {
    lawyer_name: lawyerDetails?.name || "",
    lawyer_email: lawyerDetails?.email || "",
    lawyer_phoneNumber: lawyerDetails?.phoneNumber?.split("+")?.[1] || "",
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      const newData: any = {
        name: values?.lawyer_name,
        email: values?.lawyer_email,
        phoneNumber: `+${values?.lawyer_phoneNumber}`,
      };

      const updatedValues: any = {};

      for (const key in newData) {
        if (newData[key]?.trim() !== lawyerDetails[key]) {
          updatedValues[key] = newData[key];
        }
      }

      const updatedLawyer = await updateLawyerById(
        lawyerDetails?._id,
        updatedValues
      );
      if (updatedLawyer?.success) {
        setLawyerDetails(updatedLawyer?.data);
        toast.success(
          updatedLawyer?.message ||
            "Lawyer details have been successfully updated."
        );
      } else {
        toast.error("Oops, something went wrong. Please try again later.");
      }

      setIsEditing(false);
    } catch (error) {
      toast.error("Oops, something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema: LawyerUpdateValidationSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  if (loading) {
    return <LoaderUI />;
  }

  return (
    <>
      {isEditing ? (
        <>
          <form onSubmit={handleSubmit}>
            <div className="w-full flex-auto rounded-lg bg-white p-6 shadow-lg">
              <p className="mb-4 text-lg font-semibold text-indigo-900">
                Update Lawyer Information
              </p>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div className="mb-4">
                  <label
                    htmlFor="lawyer_name"
                    className="mb-2 block text-sm font-medium text-indigo-900"
                  >
                    Lawyer Name:
                  </label>
                  <input
                    type="text"
                    id="lawyer_name"
                    name="lawyer_name"
                    placeholder="Enter Username"
                    className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.lawyer_name}
                  />
                  {errors?.lawyer_name && touched?.lawyer_name && (
                    <>
                      <div className="text-sm text-red-500">
                        {errors?.lawyer_name as string}
                      </div>
                    </>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="lawyer_email"
                    className="mb-2 block text-sm font-medium text-indigo-900"
                  >
                    Lawyer Email:
                  </label>
                  <input
                    type="email"
                    id="lawyer_email"
                    name="lawyer_email"
                    placeholder="Enter Email Address"
                    className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.lawyer_email}
                  />
                  {errors?.lawyer_email && touched?.lawyer_email && (
                    <>
                      <div className="text-sm text-red-500">
                        {errors?.lawyer_email as string}
                      </div>
                    </>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="lawyer_phoneNumber"
                    className="mb-2 block text-sm font-medium text-indigo-900"
                  >
                    Lawyer Phone:
                  </label>
                  <div className="w-full">
                    <PhoneInput
                      country="de"
                      disableDropdown={false}
                      countryCodeEditable={false}
                      enableSearch
                      searchClass="rounded-lg border-gray-300 bg-gray-100 focus:border-blue-500 focus:outline-none"
                      placeholder="Enter Phone Number"
                      value={values?.lawyer_phoneNumber}
                      onChange={(phoneNumber: string) => {
                        setFieldValue("lawyer_phoneNumber", phoneNumber);
                      }}
                      onBlur={() => setFieldTouched("lawyer_phoneNumber")}
                    />
                  </div>
                  {touched?.lawyer_phoneNumber &&
                    errors?.lawyer_phoneNumber && (
                      <div className="text-sm text-red-500">
                        {errors?.lawyer_phoneNumber as string}
                      </div>
                    )}
                </div>
              </div>
              <hr className="my-6 border-t border-gray-300" />
              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  className="border-transparent inline-flex items-center justify-center rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={handleCancelEdit}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="border-transparent inline-flex items-center justify-center rounded-md border bg-green-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Update Details
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          {lawyerDetails ? (
            <>
              <div className="mx-auto mt-6 w-full overflow-hidden rounded-lg bg-white shadow-lg">
                <div className="bg-gray-200 p-6">
                  <div className="flex items-center justify-between">
                    <h1 className="text-xl font-semibold">
                      {lawyerDetails?.name || "Lawyer Details"}
                    </h1>
                  </div>
                </div>
                <div className="container mx-auto p-6">
                  <div className="mt-4 grid gap-4 sm:grid-cols-1 md:grid-cols-3">
                    <div className="sm:col-span-2 md:col-span-3">
                      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                        <WidgetCard
                          label="Name:"
                          data={
                            lawyerDetails?.name && (
                              <span className="inline-flex items-center justify-between gap-1 text-sm text-gray-900">
                                {lawyerDetails?.name}
                              </span>
                            )
                          }
                        />
                        <WidgetCard
                          label="Email:"
                          data={
                            lawyerDetails?.email && (
                              <span className="inline-flex items-center justify-between gap-1 text-sm text-gray-900">
                                {lawyerDetails?.email}
                              </span>
                            )
                          }
                        />
                        <WidgetCard
                          label="Phone Number:"
                          data={
                            lawyerDetails?.phoneNumber && (
                              <span className="inline-flex items-center justify-between gap-1 text-sm text-gray-900">
                                {lawyerDetails?.phoneNumber}
                              </span>
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex justify-between">
                    <button
                      className="border-transparent inline-flex items-center justify-center gap-2 rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                      onClick={() => navigate("/admin/lawyer-management")}
                    >
                      <span>Back</span>
                      <TiArrowBack className="h-4 w-4" />
                    </button>
                    <button
                      className="border-transparent inline-flex items-center justify-center gap-2 rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                      onClick={toggleEditMode}
                    >
                      <span>Edit</span>
                      <ViewIcon />
                    </button>
                  </div>

                  {studentListOfLawyer?.length > 0 && (
                    <div className="container mx-auto">
                      <div className="my-4 border-t border-gray-200" />
                      <div className="mb-4 rounded-lg border-b-2 border-gray-200 bg-gray-200 p-4 px-6 py-3 md:flex md:items-center md:justify-between">
                        <div className="md:flex-1">
                          <h5 className="py-2 text-xl font-semibold text-gray-900">
                            Student Verifications
                            <span className="ml-2 text-gray-600">
                              {totalStudentCount || 0}
                            </span>
                          </h5>
                        </div>
                      </div>

                      <div className="custom-scrollbar overflow-x-auto">
                        <div className="scroll-container">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  className="cursor-pointer px-6 py-3 text-xs font-medium uppercase tracking-wider text-gray-500"
                                  onClick={() => handleSortColumn("name")}
                                >
                                  <span className="flex cursor-pointer items-center gap-2 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                    Name{" "}
                                    {sortBy === "name" &&
                                      (sortDirection === "asc" ? (
                                        <FaSortUp />
                                      ) : (
                                        <FaSortDown />
                                      ))}
                                    {sortBy !== "name" && <FaSort />}
                                  </span>
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                  Verification Status
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                  Email
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                  Phone Number
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                  Study
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                  Visa Type
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                  Entry Visa Copy
                                </th>
                                <th
                                  className="flex cursor-pointer items-center gap-2 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                  onClick={() =>
                                    handleSortColumn("visaExpiryDate")
                                  }
                                >
                                  Visa Expiry Date{" "}
                                  {sortBy === "visaExpiryDate" &&
                                    (sortDirection === "asc" ? (
                                      <FaSortUp />
                                    ) : (
                                      <FaSortDown />
                                    ))}
                                  {sortBy !== "visaExpiryDate" && <FaSort />}
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                  Street Address
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                  City
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {sortedStudentList?.length > 0 &&
                                sortedStudentList?.map((student: IStudent) => (
                                  <tr key={student?.id}>
                                    <td className="whitespace-nowrap px-10 py-2">
                                      <div className="flex items-center gap-x-3">
                                        <img
                                          className="h-9 w-9 rounded-full object-cover"
                                          src={
                                            student?.profilePic || dummyAvatar
                                          }
                                          alt="user's pic"
                                        />
                                        <div>
                                          <h2
                                            className="cursor-pointer font-medium text-gray-800 hover:text-blue-500"
                                            onClick={() =>
                                              handleViewStudentDetails(
                                                student?.studentId
                                              )
                                            }
                                          >
                                            {student?.name || "-"}
                                          </h2>
                                          <p className="text-sm text-gray-500">
                                            {student?.studentId || "-"}
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-2">
                                      {getLabel(student?.verificationStatus)}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-2">
                                      <div className="flex items-center">
                                        {student?.email}
                                        {student?.isEmailVerified && (
                                          <FaCheckCircle className="ml-2 text-green-500" />
                                        )}
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-2">
                                      <div className="flex items-center">
                                        {student?.phoneNumber || "-"}
                                        {student?.isPhoneNumberVerified && (
                                          <FaCheckCircle className="ml-2 text-green-500" />
                                        )}
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-2">
                                      {student?.study || "-"}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-2">
                                      {student?.isGermanStudyEntryVisa
                                        ? "German Study Entry Visa"
                                        : ""}
                                      {student?.isGermanStudyEntryVisa &&
                                      student?.is90daysFreeEntry
                                        ? ", "
                                        : ""}
                                      {student?.is90daysFreeEntry
                                        ? "90-days free entry allowance"
                                        : !student?.isGermanStudyEntryVisa &&
                                          !student?.is90daysFreeEntry
                                        ? " - "
                                        : ""}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-2">
                                      {student?.entryVisaCopy ? (
                                        <a
                                          href={student?.entryVisaCopy}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-blue-500 hover:underline"
                                        >
                                          View Entry Visa
                                        </a>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-2">
                                      {student?.visaExpiryDate
                                        ? moment(
                                            student?.visaExpiryDate
                                          ).format("YYYY-MM-DD")
                                        : "-"}
                                    </td>

                                    {student?.streetAddress &&
                                    student?.streetAddress?.length > 15 ? (
                                      <td
                                        className="cursor-pointer whitespace-nowrap px-6 py-2 text-blue-500 hover:underline"
                                        onClick={() =>
                                          showAddressModal(student)
                                        }
                                      >
                                        View Address
                                      </td>
                                    ) : (
                                      <td className="whitespace-nowrap px-6 py-2">
                                        {student?.streetAddress || "-"}
                                      </td>
                                    )}

                                    <td className="whitespace-nowrap px-6 py-2">
                                      {student?.city || "-"}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-2">
                                      <button
                                        onClick={() => showDeleteModal(student)}
                                        className={
                                          student?.isDeleted
                                            ? "text-green-500 hover:text-green-700"
                                            : "text-red-500 hover:text-red-700"
                                        }
                                      >
                                        {student?.isDeleted
                                          ? "Activate"
                                          : "Deactivate"}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {studentListOfLawyer?.results?.length > 0 && (
                        <div className="flex items-center justify-between p-4">
                          <div className="flex gap-3">
                            <select
                              className="rounded-md border-gray-300 text-gray-900"
                              value={rowsPerPage}
                              onChange={handleChangeRowsPerPage} // Handle rows per page change
                              id="PerPageItems"
                            >
                              <option value={5}>5 Items Per Page</option>
                              <option value={10}>10 Items Per Page</option>
                              <option value={15}>15 Items Per Page</option>
                              <option value={25}>25 Items Per Page</option>
                            </select>
                          </div>

                          {/* Pagination component */}
                          <ReactPaginate
                            pageCount={totalPageCount} // Total page count
                            pageRangeDisplayed={3} // Number of pages to display
                            marginPagesDisplayed={1} // Number of margin pages to display
                            onPageChange={handleChangePage} // Handle page change
                            containerClassName="pagination flex" // Container class name
                            activeClassName="text-blue-500 font-semibold" // Active page class name
                            pageClassName="mx-1"
                            previousClassName="mx-1"
                            nextClassName="mx-1"
                            previousLabel="Previous"
                            nextLabel="Next"
                            forcePage={currentPage}
                          />
                        </div>
                      )}
                      <ModalView
                        isOpen={isShowAddressModal}
                        onClose={hideAddressModal}
                        showActions={false}
                      >
                        <div className="p-4">
                          <h2 className="text-2xl font-semibold text-gray-900">
                            {student?.name}'s Address
                          </h2>
                          <p className="mt-2 max-w-md truncate whitespace-normal break-words text-gray-700 sm:whitespace-pre-line">
                            {student?.streetAddress}
                          </p>
                        </div>
                      </ModalView>

                      <ModalView
                        isOpen={isDeleteModal}
                        onClose={hideDeleteModal}
                        onYesClick={() =>
                          handleToggleActivation(
                            student?.studentId,
                            student?.isDeleted
                          )
                        }
                        showActions={true}
                      >
                        <div className="p-4">
                          <p className="text-md text-gray-900">
                            Are You Sure Want to
                            {student?.isDeleted
                              ? " Activate"
                              : " Deactivate"}{" "}
                            <span className="font-semibold">
                              {student?.name}
                            </span>{" "}
                            ?
                          </p>
                        </div>
                      </ModalView>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="mx-auto mt-6 w-full overflow-hidden rounded-lg bg-white shadow-lg">
              <div className="p-6 text-center">
                <p className="text-red-500">No Data Found</p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default LawyerDetails;
