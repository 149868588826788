import { emailRegex } from "utils/constants";
import { removeFirstMatch } from "utils/services/helpers";
import * as Yup from "yup";

export const LawyerCreateSchema = Yup.object().shape({
  lawyer_name: Yup.string()
    .min(3, "Lawyer name must be at least 3 characters long")
    .max(50, "Lawyer name cannot exceed 50 characters.")
    .required("Lawyer name is required"),
  lawyer_email: Yup.string()
    .matches(emailRegex, "Invalid email address")
    .required("Email is Required"),
  lawyer_phoneNumber: Yup.string()
    .min(7, "Minimum 7 digits required")
    .required("Mobile number is required")
    .test("checkEmptyValue", "Mobile number is required", function (value) {
      const { countryCode } = this.parent;
      return `+${value}` !== countryCode;
    })
    .test("checkMinNumber", "Enter Valid Mobile Number", function (value) {
      const { countryCode } = this.parent;
      return removeFirstMatch(value, countryCode?.split("+")?.[1])?.length >= 4;
    })
    .matches(/^\d{1,14}$/, "Invalid phone number"),
  lawyer_password: Yup.string()
    .trim()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,14}$/,
      "Password should be 8-14 characters with at least one lowercase, one uppercase, one number, and one special character from @$!%*?&#."
    ),
});
