import axiosInstance from "utils/services/api";

export const fetchServiceList = async (
  verificationStatus: string,
  page?: number,
  limit?: number,
  paginationEnabled?: boolean
) => {
  try {
    let URL;
    if (!paginationEnabled) {
      URL = `/lawyer/service-listing?verificationStatus=${verificationStatus}&pagination=${paginationEnabled}`;
    } else {
      URL = `/lawyer/service-listing?verificationStatus=${verificationStatus}&page=${page}&limit=${limit}`;
    }
    const response = await axiosInstance.get(URL);
    return response?.data;
  } catch (error) {
    console.error("Error fetching service list:", error);
    return null;
  }
};
