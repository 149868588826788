import { emailRegex } from "utils/constants";
import { removeFirstMatch } from "utils/services/helpers";
import * as Yup from "yup";

export const UniversityUpdateValidationSchema = Yup.object().shape({
  university_name: Yup.string()
    .min(2, "University name must be at least 2 characters long")
    .max(50, "University name cannot exceed 50 characters.")
    .required("University name is required"),
  university_email: Yup.string()
    .matches(emailRegex, "Invalid email address")
    .required("Email is Required"),
  university_phoneNumber: Yup.string()
    .required("Mobile number is required")
    .min(7, "Minimum 7 digits required")
    .test("checkEmptyValue", "Mobile number is required", function (value) {
      const { countryCode } = this.parent;
      return `+${value}` !== countryCode;
    })
    .test("checkMinNumber", "Enter Valid Mobile Number", function (value) {
      const { countryCode } = this.parent;
      return removeFirstMatch(value, countryCode?.split("+")?.[1])?.length >= 4;
    })
    .matches(/^\d{1,14}$/, "Invalid phone number"),
});
