import LoaderUI from "components/loader";
import React, { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/services/api"; // Importing Axios instance for API calls
import { IUniversity } from "./university.types";
import ModalView from "components/modal";
import { updateUniversityById } from "./university.service";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import useDebounce from "utils/hooks/useDebounce";
import { RxCrossCircled } from "react-icons/rx";

const UniversityManagement = () => {
  const [universityData, setUniversityData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [totalUniversityCount, setTotalUniversity] = useState(0);

  const [isUniversityModal, setIsUniversityModal] = useState(false);
  const [university, setUniversity] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [searchClicked, setSearchClicked] = useState(false);

  const inputRef = useRef(null);

  const handleUniversitySearch = useCallback(async (e: any) => {
    const input = e?.target?.value?.trim();
    setSearchQuery(input);

    if (input === "") {
      await fetchUniversityList();
      setSearchClicked(true);
      if (inputRef?.current) {
        inputRef?.current.focus();
      }
    }
  }, []);

  useEffect(() => {
    if (searchClicked && inputRef?.current) inputRef?.current?.focus();
  }, [searchClicked, universityData]);

  const handleClearSearch = useCallback(() => {
    setSearchQuery("");
    setSearchClicked(false);
  }, []);

  const hideDeactiveUniversityModal = () => {
    setIsUniversityModal(false);
    setUniversity(null);
  };

  const showDeactiveUniversityModal = (university: IUniversity) => {
    setUniversity(university);
    setIsUniversityModal(true);
  };

  const navigate = useNavigate();

  // Function to handle page change
  const handleChangePage = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  // Function to handle rows per page change
  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(Number(e?.target?.value));
    setCurrentPage(0);
  };

  const handleViewUniversity = (universityId: any) => {
    if (universityId) {
      let url = `/admin/university-management/${universityId}`;
      navigate(url);
    }
  };

  // Function to fetch data from API
  const fetchUniversityList = useCallback(async () => {
    setLoading(true);
    let URL;
    if (debouncedSearchQuery) {
      URL = `/admin/?page=${
        currentPage + 1
      }&limit=${rowsPerPage}&role=university&search=${debouncedSearchQuery}`;
    } else {
      URL = `/admin/?page=${
        currentPage + 1
      }&limit=${rowsPerPage}&role=university`;
    }
    try {
      const response = await axiosInstance?.get(URL);
      if (response?.data?.data && response?.data?.data?.results?.length > 0) {
        setUniversityData(response?.data?.data?.results);
        setTotalPageCount(response?.data?.data?.totalPages);
        setTotalUniversity(response?.data?.data?.totalResults);
      } else {
        setUniversityData([]);
      }
    } catch (error) {
      toast.error("Oops! Something Went Wrong Please Try Again later");
    } finally {
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, debouncedSearchQuery]);

  useEffect(() => {
    fetchUniversityList();
  }, [fetchUniversityList, debouncedSearchQuery]);

  const handleToggleActivation = async (id: string, isDeleted: boolean) => {
    setLoading(true);
    try {
      await updateUniversityById(id, {
        isDeleted: !isDeleted,
      });
      await fetchUniversityList();
      toast?.success(
        `${university?.name || "University"} ${
          isDeleted ? "is now active" : "has been deactivated"
        } successfully!`
      );
    } catch (error) {
      toast.error("oops, something went wrong, please try again later.");
    } finally {
      setLoading(false);
      setIsUniversityModal(false);
    }
  };

  // Sorting Implementation

  // Function to handle sorting when a column header is clicked
  const handleSortColumn = useCallback(
    (columnName: string) => {
      if (sortBy === columnName) {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      } else {
        setSortBy(columnName);
        setSortDirection("asc");
      }
    },
    [sortBy, sortDirection]
  );

  // Create a new array by spreading the original University to avoid mutation
  const sortedUniversityList = [...universityData]?.sort((a, b) => {
    if (sortBy) {
      // If a sorting column is selected
      // Compare the values of the sorting column in the two elements a and b
      if (a[sortBy] < b[sortBy]) {
        return sortDirection === "asc" ? -1 : 1; // Return -1 for ascending, 1 for descending
      }
      if (a[sortBy] > b[sortBy]) {
        return sortDirection === "asc" ? 1 : -1; // Return 1 for ascending, -1 for descending
      }
      return 0; // Return 0 if values are equalR
    } else {
      return 0; // Return 0 if no sorting column is selected
    }
  });

  if (loading) {
    return <LoaderUI />;
  }

  return (
    <>
      <div className="rounded-lg bg-white p-4 shadow-md">
        <div className="mb-4 rounded-lg border-b-2 border-gray-200 bg-gray-200 p-4 px-6 py-3 md:flex md:items-center md:justify-between">
          <div className="md:flex-1">
            <h5 className="py-2 text-xl font-semibold text-gray-900">
              University List
              <span className="ml-2 text-gray-600">
                {totalUniversityCount && totalUniversityCount}
              </span>
            </h5>
          </div>
          <div className="mx-1 mt-4 md:ml-4 md:mt-0 md:flex-1">
            <div className="relative mt-4 md:ml-4 md:mt-0 md:flex-1">
              <input
                ref={inputRef}
                type="text"
                placeholder="Search University by Name or Email"
                className="w-full rounded-lg border border-gray-300 bg-white px-10 py-2 transition duration-300 ease-in-out focus:border-blue-500 focus:outline-none"
                value={searchQuery}
                onChange={handleUniversitySearch}
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-300"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              {searchQuery && (
                <button
                  className="absolute inset-y-0 right-0 flex items-center justify-center px-2 focus:outline-none"
                  onClick={handleClearSearch}
                >
                  <RxCrossCircled className="mx-3 h-4 w-4 text-red-600" />
                </button>
              )}
            </div>
          </div>
          <div className="mx-1 mt-4 md:mt-0">
            <button
              className="w-full cursor-pointer rounded-lg bg-blue-500 px-6 py-2 text-white transition duration-300 ease-in-out hover:bg-blue-600 focus:bg-blue-600 focus:outline-none md:w-auto"
              onClick={() =>
                navigate("/admin/university-management/create-university")
              }
            >
              Create University
            </button>
          </div>
        </div>

        <div className="custom-scrollbar overflow-x-auto">
          <div className="scroll-container">
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    className="flex cursor-pointer items-center gap-2 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    onClick={() => handleSortColumn("name")}
                  >
                    University Name
                    {sortBy === "name" &&
                      (sortDirection === "asc" ? <FaSortUp /> : <FaSortDown />)}
                    {sortBy !== "name" && <FaSort />}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    University Email
                  </th>{" "}
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    University Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {sortedUniversityList?.length > 0 ? (
                  sortedUniversityList?.map((university: IUniversity) => (
                    <tr key={university?._id}>
                      <td
                        className="cursor-pointer whitespace-nowrap px-6 py-2"
                        onClick={() => handleViewUniversity(university?._id)}
                      >
                        {university?.name || "-"}
                      </td>
                      <td className="whitespace-nowrap px-6 py-2">
                        <div className="flex items-center">
                          {university?.email || "-"}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-6 py-2">
                        <div className="flex items-center">
                          {university?.phoneNumber || "-"}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-6 py-2">
                        <button
                          onClick={() =>
                            showDeactiveUniversityModal(university)
                          }
                          className={
                            university?.isDeleted
                              ? "text-green-500 hover:text-green-700"
                              : "text-red-500 hover:text-red-700"
                          }
                        >
                          {university?.isDeleted ? "Activate" : "Deactivate"}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={17}>
                      <div className="py-3 text-center text-gray-500">
                        No data available
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {universityData?.length > 0 && (
        <div className="flex flex-wrap items-center justify-center p-4 xs:justify-between">
          {/* Dropdown to select rows per page */}
          <select
            className="rounded-md border-gray-300 text-gray-900"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          >
            <option value={5}>5 Items Per Page</option>
            <option value={10}>10 Items Per Page</option>
            <option value={15}>15 Items Per Page</option>
            <option value={25}>25 Items Per Page</option>
          </select>

          {/* Pagination component */}
          <ReactPaginate
            pageCount={totalPageCount}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handleChangePage}
            containerClassName="pagination flex"
            activeClassName="text-blue-500 font-semibold"
            pageClassName="mx-1"
            previousClassName="mx-1"
            nextClassName="mx-1"
            previousLabel="Previous"
            nextLabel="Next"
            forcePage={currentPage}
          />
        </div>
      )}
      <ModalView
        isOpen={isUniversityModal}
        onClose={hideDeactiveUniversityModal}
        onYesClick={() =>
          handleToggleActivation(university?._id, university?.isDeleted)
        }
        showActions={true}
      >
        <div className="p-4">
          <p className="text-md text-gray-900">
            Are You Sure Want to
            {university?.isDeleted ? " Activate " : " Deactivate "}
            <span className="font-semibold">{university?.name}</span> ?
          </p>
        </div>
      </ModalView>
    </>
  );
};

export default UniversityManagement;
