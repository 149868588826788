import { brandLogo } from "assets";
import { useFormik } from "formik";
import { useState } from "react";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "utils/services/api";
import { ResetFormSchema } from "./reset.schema";
import { ResetFormTypes } from "./reset.types";
import toast from "react-hot-toast";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const handleFormSubmit = async () => {
    resetForm();

    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/auth/reset-password?token=${token}`,
        {
          password: values?.password,
        }
      );
      if (response?.data?.success) {
        toast.success(
          response?.data?.message ||
            "Password Reset Successfully, You Can Login Now"
        );
        navigate("/admin/dashboard");
      } else {
        toast.error(response?.data?.message || "An unknown error occurred");
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message;
      toast(errorMessage || "An unknown error occurred");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const initialValues: ResetFormTypes = {
    password: "",
    confirmPassword: "",
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ResetFormSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <form
      className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2"
      onSubmit={handleSubmit}
    >
      <div className="mt-[10vh] w-full max-w-[500px] flex-col items-center md:pl-4">
        <div className="form-container lg mx-auto w-full p-6 md:w-[500px]">
          <div className="mb-5 flex items-center justify-center text-center font-poppins text-[22px] font-bold uppercase text-navy-700">
            <img src={brandLogo} alt="logo" className="w-25 align-middle" />
          </div>
          <h4 className="mb-5 text-center text-3xl font-bold text-navy-700 ">
            Add Your New Password!
          </h4>

          <div className="mb-3">
            <label htmlFor="password" className="text-sm text-navy-700 ">
              New Password*
            </label>

            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="***************"
                className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span
                className="absolute right-5 top-1/2 -translate-y-1/2 transform cursor-pointer text-gray-500"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
              </span>
            </div>
            {errors?.password && touched?.password && (
              <div className="text-sm text-red-500 ">{errors?.password}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="confirmPassword" className="text-sm text-navy-700 ">
              Confirm Password*
            </label>

            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                placeholder="***************"
                className="ite cms-center mt-2 flex h-12 w-full justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span
                className="absolute right-5 top-1/2 -translate-y-1/2 transform cursor-pointer text-gray-500"
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? <RiEyeLine /> : <RiEyeOffLine />}
              </span>
            </div>
            {errors?.confirmPassword && touched?.confirmPassword && (
              <div className="text-sm text-red-500 ">
                {errors?.confirmPassword}
              </div>
            )}
          </div>

          <button
            className="linear theme-bg mt-2 flex w-full flex-row items-center justify-center rounded-xl py-[12px] text-center text-base font-medium text-white transition duration-200"
            type="submit"
          >
            Reset Password
          </button>
        </div>
      </div>
    </form>
  );
};

export default ResetPassword;
