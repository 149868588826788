import LoaderUI from "components/loader";
import { useFormik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import { useParams } from "react-router-dom";
import ViewUniversityDetails from "../ViewUniversityDetails";
import {
  fetchUniversityById,
  updateUniversityById,
} from "../university.service";
import { UniversityUpdateValidationSchema } from "./university.schema";
import { dummyAvatar } from "assets";
import { FcInfo } from "react-icons/fc";

const UniversityDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [universityDetails, setUniversityDetails] = useState(null);

  const [isEditing, setIsEditing] = useState(false);

  // Use non-optional type for input reference
  const imageInputRef = useRef<HTMLInputElement>(null);
  const matriculationInputRef = useRef<HTMLInputElement>(null);

  // State for managing selected image file
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedMatriculation, setSelectedImageMatriculation] = useState<File | null>(null);

  // Enable Edit Mode
  const toggleEditMode = () => {
    setIsEditing(true);
    clearSelectedImageAndInput();
    clearMatriculation()
  };

  // Disable Edit Mode
  const handleCancelEdit = () => {
    setIsEditing(false);
    resetForm();
    clearSelectedImageAndInput();
    clearMatriculation()
  };

  // Fetch University's Details
  const fetchUniversityDetails = useCallback(async () => {
    setLoading(true);
    const response = await fetchUniversityById(id);
    if (response?.status === 200) {
      setUniversityDetails(response?.data);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    fetchUniversityDetails();
  }, [id, fetchUniversityDetails]);

  const initialValues: any = {
    university_name: universityDetails?.name || "",
    university_email: universityDetails?.email || "",
    university_phoneNumber:
      universityDetails?.phoneNumber?.split("+")?.[1] || "",
  };

  const clearSelectedImageAndInput = () => {
    if (imageInputRef?.current) {
      imageInputRef.current.value = "";
    }
    setSelectedImage(null);
  };
  const clearMatriculation = () => {
    if (matriculationInputRef?.current) {
      matriculationInputRef.current.value = "";
    }
    setSelectedImageMatriculation(null);
    setFieldValue("university_matriculation", "");
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      let formData: any = new FormData();

      if (values?.university_name !== universityDetails.name) {
        formData.append("name", values?.university_name);
      }
      if (values?.university_email !== universityDetails.email) {
        formData.append("email", values?.university_email);
      }
      if (values?.university_phoneNumber !== universityDetails?.phoneNumber) {
        formData.append("phoneNumber", `+${values?.university_phoneNumber}`);
      }
      if (selectedImage) {
        formData.append("logo", selectedImage);
      }
      if (selectedMatriculation) {
        formData.append("uniMarticulation", selectedMatriculation);
      }

      const updatedUniversity = await updateUniversityById(
        universityDetails?._id,
        formData
      );

      if (updatedUniversity?.success) {
        setUniversityDetails(updatedUniversity?.data);
        toast.success(
          updatedUniversity?.message ||
          "University details have been successfully updated."
        );
      } else {
        toast.error("Oops, something went wrong. Please try again later.");
      }

      setIsEditing(false);
      clearSelectedImageAndInput();
    } catch (error) {
      toast.error("Oops, something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema: UniversityUpdateValidationSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  const handleImageChange = (event: any) => {
    // Extract the selected file from the event
    const file = event?.target?.files[0];
    // Reset the selected image to null
    setSelectedImage(null);

    // Check if a file has been selected
    if (file !== undefined) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

      // Check if the selected file type is allowed
      if (file && allowedTypes?.includes(file?.type)) {
        // Set the file value in the form field and update selected image
        setSelectedImage(file);
      } else {
        // Reset selected image to null and input value, display an alert for invalid file type
        clearSelectedImageAndInput();
        toast("Invalid file type. Please select PNG, JPG, JPEG file.", {
          icon: <FcInfo className="h-6 w-6" />,
        });
      }
    }
  };

  const handleMatriculationUpload = (event: any) => {
    // Extract the selected file from the event
    const file = event?.target?.files[0];
    // Reset the selected image to null
    setSelectedImageMatriculation(null);

    // Check if a file has been selected
    if (file !== undefined) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

      // Check if the selected file type is allowed
      if (file && allowedTypes?.includes(file?.type)) {
        // Set the file value in the form field and update selected image
        setSelectedImageMatriculation(file);
      } else {
        // Reset selected image to null and input value, display an alert for invalid file type
        clearSelectedImageAndInput();
        toast("Invalid file type. Please select PNG, JPG, JPEG file.", {
          icon: <FcInfo className="h-6 w-6" />,
        });
      }
    }
  };

  if (loading) {
    return <LoaderUI />;
  }

  return (
    <>
      {isEditing ? (
        <>
          <form
            onSubmit={handleSubmit}
            className="mx-auto grid rounded-lg bg-white p-6 shadow-lg"
          >
            <div className="flex flex-col items-center sm:flex-row">
              {/* University Logo */}
              <div className="mx-auto my-4">
                <input
                  type="file"
                  ref={imageInputRef}
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e)}
                />
                {/* Displaying current profile picture and allowing user to select a new one */}
                <img
                  src={
                    (selectedImage && URL?.createObjectURL(selectedImage)) ||
                    universityDetails?.logo ||
                    dummyAvatar
                  }
                  alt="university profile"
                  className="h-40 w-40 cursor-pointer rounded-full object-cover p-1 ring-2 ring-red-400"
                  onClick={() => imageInputRef?.current?.click()}
                />
              </div>
            </div>

            <div className="my-4 items-center text-[#202142]">
              {/* University Information Fields */}
              <div className="space-y-6">
                <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                  <div className="space-y-4">
                    <label
                      htmlFor="university_name"
                      className="block text-sm font-medium text-indigo-900"
                    >
                      University Name:
                    </label>
                    <input
                      type="text"
                      id="university_name"
                      name="university_name"
                      placeholder="Enter University Name"
                      className="focus:shadow-outline focus:border-img-500 block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:outline-none"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.university_name}
                    />
                    {errors?.university_name && touched?.university_name && (
                      <div className="text-sm text-red-500">
                        {errors?.university_name as string}
                      </div>
                    )}
                  </div>

                  <div className="space-y-4">
                    <label
                      htmlFor="university_email"
                      className="block text-sm font-medium text-indigo-900"
                    >
                      University Email:
                    </label>
                    <input
                      type="email"
                      id="university_email"
                      name="university_email"
                      placeholder="Enter University Email Address"
                      className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.university_email}
                    />
                    {errors?.university_email && touched?.university_email && (
                      <div className="text-sm text-red-500">
                        {errors?.university_email as string}
                      </div>
                    )}
                  </div>

                  <div className="space-y-4">
                    <label
                      htmlFor="university_phoneNumber"
                      className="block text-sm font-medium text-indigo-900"
                    >
                      University Phone:
                    </label>
                    <div className="w-full">
                      <PhoneInput
                        country="de"
                        disableDropdown={false}
                        countryCodeEditable={false}
                        enableSearch
                        searchClass="rounded-lg border-gray-300 bg-gray-100 focus:border-blue-500 focus:outline-none"
                        placeholder="Enter University Phone Number"
                        value={values?.university_phoneNumber}
                        onChange={(phoneNumber: string) => {
                          setFieldValue("university_phoneNumber", phoneNumber);
                        }}
                        onBlur={() => setFieldTouched("university_phoneNumber")}
                      />
                    </div>
                    {touched?.university_phoneNumber && errors?.university_phoneNumber && (
                      <div className="text-sm text-red-500">
                        {errors?.university_phoneNumber as string}
                      </div>
                    )}
                  </div>

                </div>
                <div className="space-y-4">
                  <label
                    htmlFor="university_matriculation"
                    className="block text-sm font-medium text-indigo-900"
                  >
                    University Matriculation Letter:
                  </label>
                  <div className="relative">
                    <input
                      type="file"
                      name="university_matriculation"
                      id="university_matriculation"
                      ref={matriculationInputRef}
                      className="hidden"
                      accept="image/*"
                      onChange={(e) => handleMatriculationUpload(e)}
                      onBlur={handleBlur}
                      onKeyDown={(e) => {
                        if (e.key === "Escape") {
                          handleBlur(e);
                        }
                      }}
                    />
                    <div
                      className="cursor-pointer border border-gray-300 bg-gray-100 p-2 rounded-lg flex items-center justify-center overflow-hidden"
                      onClick={() => matriculationInputRef?.current?.click()}
                    >
                      {selectedMatriculation ? (
                        <img
                          src={URL.createObjectURL(selectedMatriculation)}
                          alt="University Matriculation Letter"
                          className="max-h-60 max-w-full object-contain"
                        />
                      ) : universityDetails?.uniMarticulation ? (
                        <img
                          src={universityDetails?.uniMarticulation}
                          alt="University Matriculation Letter"
                          className="max-h-60 max-w-full object-contain"
                        />
                      ) : (
                        <div className="text-gray-500">Upload Matriculation Letter</div>
                      )}
                    </div>
                  </div>
                  {errors?.university_matriculation && touched?.university_matriculation && (
                    <div className="text-sm text-red-500 mt-1">
                      {errors?.university_matriculation as string}
                    </div>
                  )}
                </div>

              </div>


              {/* Buttons */}
              <hr className="my-6 border-t border-gray-300" />
              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  className="border-transparent inline-flex items-center justify-center rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={handleCancelEdit}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="border-transparent inline-flex items-center justify-center rounded-md border bg-green-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Update Details
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          <ViewUniversityDetails
            universityDetails={universityDetails}
            toggleEditMode={toggleEditMode}
          />
        </>
      )}
    </>
  );
};

export default UniversityDetails;
