import { emailRegex } from "utils/constants";
import { removeFirstMatch } from "utils/services/helpers";
import * as Yup from "yup";

export const StudentUpdateValidationSchema = Yup.object().shape({
  studentId: Yup.string().required("Student ID is required"),
  student_name: Yup.string()
    .min(2, "Minimum 2 Characters")
    .max(20, "Maximum 20 Characters")
    .required("Student Name is required"),
  student_email: Yup.string()
    .matches(emailRegex, "Invalid email address")
    .required("Email is Required"),
  student_phoneNumber: Yup.string()
    .min(7, "Minimum 7 digits required")
    .required("Mobile number is required")
    .test("checkEmptyValue", "Mobile number is required", function (value) {
      const { countryCode } = this.parent;
      return `+${value}` !== countryCode;
    })
    .test("checkMinNumber", "Enter Valid Mobile Number", function (value) {
      const { countryCode } = this.parent;
      return removeFirstMatch(value, countryCode?.split("+")?.[1])?.length >= 4;
    })
    .matches(/^\d{1,14}$/, "Invalid phone number"),
});
