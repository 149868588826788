import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import LoaderUI from "components/loader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import ReactLoading from "react-loading";
import ReactPaginate from "react-paginate";
import { getEnumLabel } from "utils/services/helpers";
import { fetchServiceList } from "./dashboard.services";

const accordionData = [
  {
    id: "panel1",
    label: "Open",
  },
  {
    id: "panel2",
    label: "Ready",
  },
  {
    id: "panel3",
    label: "Waiting For Correction",
  },
  {
    id: "panel4",
    label: "Correction Ready",
  },
  {
    id: "panel5",
    label: "Ready For LEA",
  },
  {
    id: "panel6",
    label: "At LEA",
  },
  {
    id: "panel7",
    label: "Invited",
  },
  {
    id: "panel8",
    label: "Ready For Invoice",
  },
  {
    id: "panel9",
    label: "Invoiced",
  },
  {
    id: "panel10",
    label: "Out",
  },
];

export default function Dashboard() {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingAccordion, setLoadingAccordion] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [statusCounts, setStatusCounts] = useState([]);
  const [totalStudentCount, setTotalStudents] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentStatus, setCurrentStatus] = useState("OPEN");
  const [currentPage, setCurrentPage] = useState(0);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [groupedStudents, setGroupedStudents] = useState<any[][]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paginationEnabled, setPaginationEnabled] = useState(true);

  const handleNextUniversity = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const currentUniversityData = groupedStudents[currentIndex];

  const getTheDashboardData = async () => {
    setLoading(true);
    try {
      const response = await fetchServiceList(
        currentStatus,
        currentPage + 1,
        rowsPerPage,
        paginationEnabled
      );
      setStudentList(response?.data?.results);
      setTotalStudents(response?.data?.totalCount);
      setStatusCounts(response?.data?.allCounts);
      setTotalPageCount(response?.data?.totalPages);
    } catch (error: any) {
      console.log("Something Went Wrong", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTheDashboardData();
  }, [currentPage, rowsPerPage, paginationEnabled]);

  const handleCheckboxChange = (event: any, user: any) => {
    const userId = user?._id; // Unique identifier of the user

    // Check if the user is already selected
    const selectedIndex = selectedUsers.findIndex(
      (selectedUser: any) => selectedUser?._id === userId
    );

    // If the user is not selected, add them to the selection
    if (selectedIndex === -1) {
      // Add the user to the selection
      handleSelect([...selectedUsers, user]);
    } else {
      // If the user is selected, remove them from the selection
      const updatedSelection = [...selectedUsers];
      updatedSelection.splice(selectedIndex, 1); // Remove the user from the selection
      handleSelect(updatedSelection); // Update the selection state
    }
  };

  // Function to handle page change
  const handleChangePage = ({ selected }: { selected: number }) => {
    setCurrentPage(selected); // Increment selected by 1 because API page starts from 1
  };

  // Function to handle rows per page change
  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(Number(e?.target?.value)); // Set rows per page based on selected value
    setCurrentPage(0);
  };

  const handleChange =
    (panel: string, status: string) =>
    async (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setPaginationEnabled(true);
      setSelectedUsers([]);
      const currentStatusLabel = getEnumLabel(status); // Capture the current status label
      setCurrentStatus(currentStatusLabel); // Update the current status

      if (isExpanded && currentStatusLabel !== currentStatus) {
        // Check if the status is different from the current status
        setLoadingAccordion(true);
        try {
          const response = await fetchServiceList(
            currentStatusLabel, // Use the captured current status label
            currentPage + 1,
            rowsPerPage,
            paginationEnabled
          );
          setStudentList(response?.data?.results);
          setTotalStudents(response?.data?.totalCount);
          setStatusCounts(response?.data?.allCounts);
        } catch (error: any) {
          console.log("Something Went Wrong", error);
        } finally {
          setLoadingAccordion(false);
        }
      }
    };

  const handleSelect = (users: string[]) => {
    setSelectedUsers(users);
  };

  //
  const groupStudentsByUniversity = (students: any[]) => {
    const universityGroups: any[][] = [];

    students.forEach((student: any) => {
      const universityName = student?.universityName;
      const existingGroup = universityGroups.find(
        (group) => group[0] === universityName
      );
      if (existingGroup) {
        existingGroup.push(student);
      } else {
        universityGroups.push([universityName, student]);
      }
    });

    return universityGroups;
  };

  const handleGenerateInvoice = (selectedUsers: any) => {
    console.log("🚀 ~ handleGenerateInvoice ~ selectedUsers:", selectedUsers);
    setInvoiceModal(true);
    const universityGroups = groupStudentsByUniversity(selectedUsers);
    console.log("University-wise Student Groups:", universityGroups);
    setGroupedStudents(universityGroups);
  };

  const handleGenerateInvoiceModalClose = () => {
    setInvoiceModal(false);
    setSelectedUsers([]);
    setGroupedStudents([]);
    setCurrentIndex(0);
  };

  if (loading) {
    return <LoaderUI />;
  }

  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = currentYear - 3; i <= currentYear + 1; i++) {
    years?.push(i);
  }

  return (
    <div className="mt-4">
      {invoiceModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-[#000] bg-opacity-60"></div>
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block max-h-[80vh] transform overflow-y-auto rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:align-middle">
              <div className="bg-white p-5">
                <>
                  <div className="mb-6 flex items-center justify-between text-2xl font-bold">
                    <h1>Create Invoice</h1>
                    <button
                      className="text-red-400 hover:text-red-600 focus:outline-none"
                      onClick={handleGenerateInvoiceModalClose}
                    >
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </button>
                  </div>

                  {currentUniversityData && (
                    <>
                      <div className="grid grid-cols-2 gap-4">
                        <div className="flex flex-col">
                          <span className="text-sm text-gray-600">
                            Invoice number:
                          </span>
                          <span className="text-lg font-medium">2023-04</span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-sm text-gray-600">
                            Year of delivery:
                          </span>
                          <select className="rounded-md border border-gray-300 px-3 py-1 text-lg font-medium focus:outline-none">
                            {years.map((year) => (
                              <option
                                key={year}
                                value={year}
                                selected={year === currentYear}
                              >
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                        <span className="self-center text-lg font-medium">
                          {currentUniversityData[0]}
                        </span>
                      </div>

                      <div className="mt-6 overflow-x-auto">
                        <div className="custom-scrollbar overflow-x-auto">
                          <div className="scroll-container">
                            <table className="w-full table-auto">
                              <thead>
                                <tr className="bg-gray-100 text-left text-xs uppercase">
                                  <th className="px-4 py-2">Global #</th>
                                  <th className="px-4 py-2">#</th>
                                  <th className="px-4 py-2">Student ID</th>
                                  <th className="px-4 py-2">Name</th>
                                  <th className="px-4 py-2">University Name</th>
                                  <th className="px-4 py-2">Comments</th>
                                  <th className="px-4 py-2">Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* Render students */}
                                {currentUniversityData &&
                                  currentUniversityData
                                    .slice(1)
                                    .map((student, index) => (
                                      <tr key={index} className="border-b">
                                        <td className="whitespace-nowrap px-6 py-2">
                                          {student._id}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-2">
                                          {student.phoneNumber}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-2">
                                          {student.studentId}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-2">
                                          {student.name}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-2">
                                          {student.universityName}
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-2">
                                          <input
                                            type="text"
                                            className="rounded-md border border-gray-300 px-2 py-1 focus:outline-none"
                                            defaultValue={student.comments}
                                          />
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-2">
                                          {student.price}
                                        </td>
                                      </tr>
                                    ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="mt-6 flex justify-between">
                        <div>
                          <p className="text-sm text-gray-600">Subtotal:</p>
                          <p className="text-lg font-medium">100,00 €</p>
                        </div>
                        <div>
                          <p className="text-sm text-gray-600">19% VAT:</p>
                          <p className="text-lg font-medium">19,00 €</p>
                        </div>
                        <div>
                          <p className="text-sm text-gray-600">Total:</p>
                          <p className="text-lg font-semibold">119,00 €</p>
                        </div>
                      </div>

                      <div className="mt-6 flex justify-between space-x-4">
                        <button
                          className="rounded-md bg-gray-200 px-4 py-2 font-semibold text-gray-700 transition-colors duration-300 ease-in-out hover:bg-gray-300 focus:outline-none"
                          onClick={handleGenerateInvoiceModalClose}
                        >
                          Cancel
                        </button>
                        {/* Disable Next button when currentIndex is at the last index */}
                        <button
                          className="rounded-md bg-green-500 px-4 py-2 font-semibold text-white transition-colors duration-300 ease-in-out hover:bg-green-700 focus:outline-none"
                          onClick={handleNextUniversity}
                          disabled={
                            currentIndex === groupedStudents?.length - 1
                          }
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        {accordionData?.map((item, index) => (
          <Accordion
            className="my-2"
            key={item.id}
            expanded={expanded === item.id}
            onChange={handleChange(item.id, item.label)}
          >
            <AccordionSummary
              expandIcon={<IoIosArrowDown />}
              id={`${item.id}-header`}
            >
              <div className="w-1/2">
                <h2>{item.label}</h2>
              </div>
              <div className="w-1/2">
                <p>
                  {statusCounts?.find(
                    (count) =>
                      count?.status === getEnumLabel(item?.label)?.toUpperCase()
                  )?.filterCount || 0}{" "}
                  / {totalStudentCount || 0}
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {loadingAccordion ? (
                <div className="flex items-center justify-center py-9">
                  <ReactLoading
                    type={"spin"}
                    color={"#e39d6b"}
                    height={30}
                    width={30}
                  />
                </div>
              ) : (
                <>
                  {selectedUsers?.length > 0 &&
                    currentStatus === "READY_FOR_INVOICE" && (
                      <div className="my-2 flex justify-end">
                        <button
                          className="rounded bg-blue-500 px-2 py-1 text-white hover:bg-blue-700"
                          onClick={() => handleGenerateInvoice(selectedUsers)}
                        >
                          Generate Invoice
                        </button>
                      </div>
                    )}

                  <div className="custom-scrollbar overflow-x-auto">
                    <div className="scroll-container">
                      {studentList?.length > 0 ? (
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              {currentStatus === "READY_FOR_INVOICE" && (
                                <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider text-gray-500">
                                  {/* Empty table header cell for checkbox */}
                                </th>
                              )}

                              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                Student ID
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                Visa Expiry
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                City
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                Process
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                Name
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                Tel
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {studentList?.map((student: any) => (
                              <tr key={student?._id}>
                                {currentStatus === "READY_FOR_INVOICE" && (
                                  <td className="whitespace-nowrap px-1 py-2">
                                    <label className="flex cursor-pointer items-center justify-center">
                                      <input
                                        type="checkbox"
                                        className="hidden"
                                        onChange={(e) =>
                                          handleCheckboxChange(e, student)
                                        }
                                        checked={selectedUsers.some(
                                          (selectedUser: any) =>
                                            selectedUser?._id === student?._id
                                        )}
                                      />
                                      <div className="flex h-4 w-4 items-center justify-center rounded border border-gray-300 bg-white">
                                        {selectedUsers.some(
                                          (selectedUser: any) =>
                                            selectedUser?._id === student?._id
                                        ) && (
                                          <div className="pointer-events-none h-2 w-2 bg-theme"></div>
                                        )}
                                      </div>
                                    </label>
                                  </td>
                                )}

                                <td className="whitespace-nowrap px-6 py-2">
                                  {student?.studentId || "-"}
                                </td>
                                <td className="whitespace-nowrap px-6 py-2">
                                  {student?.visaExpiryDate
                                    ? moment(student?.visaExpiryDate).format(
                                        "YYYY-MM-DD"
                                      )
                                    : "-"}
                                </td>
                                <td className="whitespace-nowrap px-6 py-2">
                                  {student?.city || "-"}
                                </td>
                                <td className="whitespace-nowrap px-6 py-2">
                                  {student?.study || "-"}
                                </td>
                                <td className="whitespace-nowrap px-6 py-2">
                                  {student?.name || "-"}
                                </td>
                                <td className="whitespace-nowrap px-6 py-2">
                                  <div className="flex items-center">
                                    {student?.phoneNumber || "-"}
                                    {student?.isPhoneNumberVerified && (
                                      <FaCheckCircle className="ml-2 text-green-500" />
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="py-3 text-center text-gray-500">
                          No data available
                        </div>
                      )}
                    </div>
                  </div>
                  {studentList?.length > 0 && (
                    <div className="flex items-center justify-between p-4">
                      <label className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          className="form-checkbox h-5 w-5 text-orange-500"
                          checked={paginationEnabled}
                          onChange={(e) =>
                            setPaginationEnabled(e?.target?.checked)
                          }
                        />
                        <span className="text-sm text-gray-600">
                          Pagination
                        </span>
                      </label>

                      {paginationEnabled && (
                        <div className="flex gap-3">
                          <select
                            className="rounded-md border-gray-300 text-gray-900"
                            value={rowsPerPage}
                            onChange={handleChangeRowsPerPage} // Handle rows per page change
                            id="PerPageItems"
                          >
                            <option value={5}>5 Items Per Page</option>
                            <option value={10}>10 Items Per Page</option>
                          </select>
                        </div>
                      )}
                    </div>
                  )}
                  {studentList?.length > 0 && paginationEnabled && (
                    <div className="flex justify-center p-4">
                      {/* Pagination component */}
                      <ReactPaginate
                        pageCount={totalPageCount} // Total page count
                        pageRangeDisplayed={3} // Number of pages to display
                        marginPagesDisplayed={1} // Number of margin pages to display
                        onPageChange={handleChangePage} // Handle page change
                        containerClassName="pagination flex" // Container class name
                        activeClassName="text-blue-500 font-semibold" // Active page class name
                        pageClassName="mx-1"
                        previousClassName="mx-1"
                        nextClassName="mx-1"
                        previousLabel="Previous"
                        nextLabel="Next"
                        forcePage={currentPage}
                      />
                    </div>
                  )}
                </>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}
