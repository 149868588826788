import axiosInstance from "utils/services/api";
import { ILawyer } from "./lawyer.types";

export const fetchLawyerById = async (
  id: string,
  page?: number,
  limit?: number
) => {
  try {
    const response = await axiosInstance.get(
      `admin/lawyer/${id}?page=${page}&limit=${limit}`
    );
    return response?.data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const updateLawyerById = async (
  id: string,
  updatedLawyerDetails: ILawyer
) => {
  try {
    const response = await axiosInstance.patch(
      `admin/lawyer/${id}`,
      updatedLawyerDetails
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

// Create Lawyer
export const createLawyer = async (lawyerDetails: ILawyer) => {
  try {
    const response = await axiosInstance.post(`admin`, lawyerDetails);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
