import axiosInstance from "utils/services/api";
import { IStudent } from "./student.types";

export const fetchStudentById = async (studentId: string) => {
  try {
    const response = await axiosInstance.get(`admin/student/${studentId}`);
    return response?.data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const updateStudentById = async (
  studentId: string,
  updatedData: IStudent
) => {
  try {
    const response = await axiosInstance.patch(
      `admin/student/${studentId}`,
      updatedData
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const fetchStudentDashboardDataById = async (Id: string) => {
  try {
    const response = await axiosInstance.get(`dashboard/${Id}`);
    return response;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const searchStudent = async (
  searchTerm: string,
  page: any,
  limit: any
) => {
  try {
    const response = await axiosInstance.get(
      `admin/student?search=${searchTerm}&page=${page + 1}&limit=${limit}`
    );
    return response?.data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
