import { ViewIcon, dummyAvatar } from "assets";
import WidgetCard from "components/widgetcard";
import { TiArrowBack } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { IUniversity } from "../university.types";
import { RiPencilLine } from "react-icons/ri";

const ViewUniversityDetails = ({
  universityDetails,
  toggleEditMode,
}: {
  universityDetails: IUniversity;
  toggleEditMode: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <>
      {universityDetails ? (
        <>
          <div className="mx-auto mt-6 w-full overflow-hidden rounded-lg bg-white shadow-lg">
            {/*  */}
            <div className="bg-gray-200 p-6">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                  <img
                    className="mr-4 h-14 w-14 cursor-not-allowed rounded-full object-cover"
                    src={universityDetails?.logo || dummyAvatar}
                    alt="Student"
                  />
                  <h1 className="mr-1 text-xl font-semibold">
                    {universityDetails?.name || "University Details"}
                  </h1>
                  <RiPencilLine
                    className="h-6 w-6 cursor-pointer text-green-500"
                    onClick={toggleEditMode}
                  />
                </div>
                <div>
                  <span
                    className="inline-flex cursor-pointer items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-gray-700"
                    onClick={() => navigate("/admin/university-management")}
                  >
                    <span>Back</span>
                    <TiArrowBack className="h-4 w-4" />
                  </span>
                </div>
              </div>
            </div>

            {/*  */}
            <div className="container mx-auto p-6">
              <div className="mt-4 grid gap-4 sm:grid-cols-1 md:grid-cols-3">
                <div className="sm:col-span-2 md:col-span-3">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                    <WidgetCard
                      label="Name:"
                      data={
                        universityDetails?.name && (
                          <span className="inline-flex items-center justify-between gap-1 text-sm text-gray-900">
                            {universityDetails?.name}
                          </span>
                        )
                      }
                    />
                    <WidgetCard
                      label="Email:"
                      data={
                        universityDetails?.email && (
                          <span className="inline-flex items-center justify-between gap-1 text-sm text-gray-900">
                            {universityDetails?.email}
                          </span>
                        )
                      }
                    />
                    <WidgetCard
                      label="Phone Number:"
                      data={
                        universityDetails?.phoneNumber && (
                          <span className="inline-flex items-center justify-between gap-1 text-sm text-gray-900">
                            {universityDetails?.phoneNumber}
                          </span>
                        )
                      }
                    />
                  </div>
                </div>
                <div>
                  <WidgetCard
                    label="Study QR:"
                    data={
                      universityDetails?.studyQR && (
                        <img
                          className="h-32 w-auto rounded-md border border-gray-200"
                          src={universityDetails?.studyQR}
                          alt="Study QR"
                        />
                      )
                    }
                  />
                  {universityDetails?.uniLinks?.study && (
                    <WidgetCard
                      label="Study Link:"
                      data={
                        universityDetails?.uniLinks?.study && (
                          <a
                            href={`https://devapp.sers.legal/register?uni=${universityDetails?.name}&process=study`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:underline"
                          >
                            {universityDetails?.uniLinks?.study}
                          </a>
                        )
                      }
                    />
                  )}
                </div>
                <div>
                  <WidgetCard
                    label="Post Study QR:"
                    data={
                      universityDetails?.postStudyQR && (
                        <img
                          className="h-32 w-auto rounded-md border border-gray-200"
                          src={universityDetails?.postStudyQR}
                          alt="Post Study QR"
                        />
                      )
                    }
                  />
                  {universityDetails?.uniLinks?.postStudy && (
                    <WidgetCard
                      label="Post Study Link:"
                      data={
                        universityDetails?.uniLinks?.postStudy && (
                          <a
                            href={`https://devapp.sers.legal/register?uni=${universityDetails?.name}&process=post-study`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:underline"
                          >
                            {universityDetails?.uniLinks?.postStudy}
                          </a>
                        )
                      }
                    />
                  )}
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  className="border-transparent inline-flex items-center justify-center gap-2 rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={toggleEditMode}
                >
                  <span>Edit</span>
                  <ViewIcon />
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="mx-auto mt-6 w-full overflow-hidden rounded-lg bg-white shadow-lg">
          <div className="p-6 text-center">
            <p className="text-red-500">No Data Found</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewUniversityDetails;
